<template>
  <NavigationLink
    v-for="nav in navigation"
    :key="nav.path"
    :nav="nav"
    @click="$emit('navigate')"
  />
</template>

<script setup lang="ts">
import type { NavigationLinkType } from '@/types'

defineEmits([ 'navigate' ])
const navigation: NavigationLinkType[] = [
  {
    label: 'Dashboard',
    path: '/',
    icon: 'nav-dashboard'
  },
  {
    label: 'Shop',
    children: [
      {
        label: 'Oats Overnight',
        path: '/subscriptions/oatmeal/new',
        icon: 'nav-oatmeal',
        isChild: true
      },
      {
        label: 'Protein Coffee',
        path: '/subscriptions/coffee/new',
        icon: 'nav-protein-coffee',
        isChild: true
      },
      {
        label: 'Merchandise',
        path: '/products/merchandise',
        icon: 'nav-merch'
      }
    ]
  },
  {
    label: 'Community',
    children: [
      {
        label: 'Gifting',
        path: '/gifting',
        icon: 'nav-gifting'
      },
      {
        label: 'Rating & Notes',
        path: '/favorites',
        icon: 'nav-favorites'
      },
      {
        label: 'Refer a Friend',
        path: '/referrals',
        icon: 'referral'
      }
    ]
  }
]
</script>
